import React, { useState } from 'react';

import styles from './LoginForm.module.scss';

const initialState = {
  login: '',
  password: ''
};

const LoginForm = ({ loginHandler }) => {
  const [form, setForm] = useState(initialState);

  const changeHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    loginHandler(form);
  };

  return (
    <div className={styles.wrapper}>
      <form onSubmit={submitHandler}>
        <label htmlFor="login" className={styles.label}>
          Логин *
        </label>
        <input
          className={styles.input}
          id="login"
          type="text"
          name="login"
          value={form.login}
          onChange={changeHandler}
          required
        />

        <label htmlFor="password" className={styles.label}>
          Пароль *
        </label>
        <input
          className={styles.input}
          id="password"
          type="password"
          name="password"
          value={form.password}
          onChange={changeHandler}
          required
        />
        <button className="button">Войти</button>
      </form>
    </div>
  );
};

export default LoginForm;
