import React from 'react';
import styles from './StatisticByMethodTable.module.scss';

const StatisticByMethodTable = ({rows}) => {
    return (
        <div className={styles.table}>
            <div className={styles.headingRow}>
                <p style={{
                    textAlign: 'left'
                }} className={styles.headingText}>Школа (идентификатор)</p>
                <p className={styles.headingText}>Назначено</p>
                <p style={{
                    textAlign: 'right'
                }} className={styles.headingText}>Пройдено</p>
            </div>
            <div className={styles.rows}>
                {
                    rows?.length > 0 && rows?.map((row, index) => {
                        return <div key={index} className={styles.tableRow}>
                            <p style={{
                                justifyContent: 'flex-start',
                                textAlign: 'left'
                            }} className={styles.tableRowText}>{row?.school}</p>
                            <p className={styles.tableRowText}>{row?.total}</p>
                            <p style={{
                                justifyContent: 'flex-end',
                                textAlign: 'right'
                            }} className={styles.tableRowText}>{row?.finished}</p>
                        </div>
                    })
                }
            </div>


        </div>
    );
};

export default StatisticByMethodTable;