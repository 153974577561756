import React from 'react';
import styles from './StatisticByParamBlock.module.scss';
import { Link, useParams } from 'react-router-dom';
import ArrowLeftIcon from '../../../static/img/ic-arrowLeft.svg';
import StatisticByParamTable from './StatisticByParamTable';
import { useQuery } from '@tanstack/react-query';
import { fetchStatisticByParam } from '../../../api/fetchGeneralStatistic';
import { useSelector } from 'react-redux';
import { STATISTIC_PARAMS } from './consts';
import { Loader } from '../../Loader';

const StatisticByParamBlock = () => {
    const {param, period} = useParams();
    const dashboard = useSelector((state) => state.dashboard);

    const { data, error, isLoading } = useQuery({
        queryKey: ['statistic/' + param, period, dashboard.currentRegion, dashboard.currentMunicipality?._id, dashboard?.currentSchool?._id ],
        queryFn: async () => {
            return await fetchStatisticByParam(param, period, dashboard.currentRegion, dashboard.currentMunicipality?._id, dashboard?.currentSchool?._id)
        }
    });

    return (
        <>
            {isLoading && <Loader />}

            {error && <div className="data-error">{error.message}</div>}

            {data && <div className={styles.statisticByParamBlock}>
                <Link to={'/statisticDashboard'} className={styles.backLink}>
                    <img src={ArrowLeftIcon} alt="Назад" />
                    <p>Назад</p>
                </Link>

                <h2 className={styles.heading}>{STATISTIC_PARAMS[param].heading}</h2>
                <StatisticByParamTable rows={data} />
            </div>}
        </>
    );
};

export default StatisticByParamBlock;