import React from 'react';
import styles from './DashboardTotalData.module.scss';
import HatIcon from '../../../static/img/ic-hat.svg';
import PersonIcon from '../../../static/img/ic-person.svg';
import { Link } from 'react-router-dom';

const DashboardTotalData = ({pupilsTotal, psychsTotal}) => {
    return (
        <div className={styles.totalData}>
            <Link to={'/statistic/byParam/pupils'} className={styles.totalDataCard}>
                <div className={styles.iconHolder}>
                    <img src={HatIcon} alt="Шляпа" />
                </div>
                <div className={styles.totalDataTextBlock}>
                    <p className={styles.totalDataNumber}>{pupilsTotal || 0}</p>
                    <p className={styles.totalDataLabel}>Учеников подключено</p>
                </div>
            </Link>
            <Link to={'/statistic/byParam/psychs'} className={styles.totalDataCard}>
                <div className={styles.iconHolder}>
                    <img src={PersonIcon} alt="Человек" />
                </div>
                <div className={styles.totalDataTextBlock}>
                    <p className={styles.totalDataNumber}>{psychsTotal || 0}</p>
                    <p className={styles.totalDataLabel}>Психологов подключено</p>
                </div>
            </Link>
        </div>
    );
};

export default DashboardTotalData;