import axios from 'axios';

import { ADMINS_CHANGE_PASSWORD, ADMINS_LIST } from '../types';
import {
  finishLoading,
  showAlert,
  startLoading
} from '../actions/setAppActions';

export function fetchAdmins() {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const { data } = await axios.get('admin/list');
      dispatch({ type: ADMINS_LIST, payload: data });
    } catch (e) {
      const text = e.response?.data?.message || e.message;
      dispatch(showAlert({ type: 'error', text }));
    } finally {
      dispatch(finishLoading());
    }
  };
}

export function changeAdminPassword(id, password) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      await axios.put(`admin/change-password`, {
        id,
        password
      });

      dispatch(showAlert({ type: 'success', text: 'Пароль успешно изменён' }));

      setTimeout(() => {
        dispatch(hideChangePasswordModal());
      }, 3000);
    } catch (e) {
      const text = e.response?.data?.message || e.message;
      dispatch(showAlert({ type: 'error', text }));
    } finally {
      dispatch(finishLoading());
    }
  };
}

export function showChangePasswordModal(id) {
  return async (dispatch) => {
    dispatch({
      type: ADMINS_CHANGE_PASSWORD,
      payload: id
    });
  };
}

export function hideChangePasswordModal() {
  return async (dispatch) => {
    dispatch({
      type: ADMINS_CHANGE_PASSWORD,
      payload: null
    });
  };
}
