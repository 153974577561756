import styles from './ByMethodStatisticContent.module.scss';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from '../../../static/img/ic-search.svg'
import ArrowDown from '../../../static/img/ic-arrowDown.svg'
import { useQuery } from '@tanstack/react-query';
import { fetchDashboardMethodsByDate } from '../../../api/fetchGeneralStatistic';
import { Loader } from '../../Loader';
import { useSelector } from 'react-redux';
import ArrowLeftIcon from '../../../static/img/ic-arrowLeft.svg';

export const ByMethodStatisticContent = ({currentPage, setCurrentPage, setTotalPages}) => {
    const [dateValue, setDateValue] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [chosenMethod, setChosenMethod] = useState(null);
    const [filteredMethods, setFilteredMethods] = useState([]);
    const dashboard = useSelector(state => state.dashboard);

    const { data, error, isLoading } = useQuery({
        queryKey: ['statistic/dashboard', dateValue, dashboard.currentRegion, dashboard.currentMunicipality?._id, dashboard?.currentSchool?._id],
        queryFn: async () => {
            return await fetchDashboardMethodsByDate(dateValue, dashboard.currentRegion, dashboard.currentMunicipality?._id, dashboard?.currentSchool?._id)
        }
    });

    const handleClick = (item) => {
        setChosenMethod(item);
    }
    const handleDateUpdate = (e) => {
        const dateValue = e.target.value;
        setCurrentPage(0);
        setDateValue(dateValue);
    }
    const handleTextChange = (e) => {
        const value = e.target.value;
        setSearchText(value);
    }
    const datePickerStyleByBrowser = () => {
        if(navigator.userAgent.search('Chrome') !== -1) return styles.dateInput
        else if(navigator.userAgent.search('Safari') !== -1) return styles.dateInputSafari
        else if(navigator.userAgent.search('Mozilla') !== -1) return styles.dateInputMozilla
        else return styles.dateInput
    }

    useEffect(() => {
        let newFilteredMethods = data;
        if(searchText){
            const methodsByNewSearch = []
            for(let method of data){
                if(method.name.toLowerCase().includes(searchText.toLowerCase())){
                    methodsByNewSearch.push(method);
                }
            }
            newFilteredMethods = methodsByNewSearch;
        }
        setTotalPages(Math.ceil(newFilteredMethods / 15));
        newFilteredMethods?.slice(15 * currentPage, 15 * (currentPage + 1));
        setFilteredMethods(newFilteredMethods);
    }, [data, searchText])

    return (
        <div className={styles.byMethodStatisticContent}>
            <Link to={'/statisticDashboard'} className={styles.backLink}>
                <img src={ArrowLeftIcon} alt="Назад"/>
                <p>Назад</p>
            </Link>
            <h2 className={styles.heading}>Статистика по методикам</h2>
            {(dashboard.currentRegion || dashboard.currentMunicipality.name || dashboard.currentSchool.name)
                && <div className={styles.filters}>
                    {dashboard.currentRegion && <p className={styles.filtersText}>{dashboard.currentRegion}</p>}
                    {dashboard.currentMunicipality.name && <p className={styles.filtersText}>{dashboard.currentMunicipality.name}</p>}
                    {dashboard.currentSchool.name && <p className={styles.filtersText}>{dashboard.currentSchool.name}</p>}
                </div>}

            <p className={styles.description}>Выберите условие и дату теста</p>
            <div className={styles.contentHeader}>
                <div className={styles.filters}>
                    <div className={styles.search}>
                        <img src={SearchIcon} alt={"Поиск"} />
                        <input value={searchText} onChange={handleTextChange} className={styles.input}
                               placeholder={'Поиск'} />
                    </div>
                    <div className={styles.dateInputHolder}>
                        <input name={'datePicker'} className={datePickerStyleByBrowser()} type={'date'}
                               onChange={handleDateUpdate} />
                        <div style={{
                            display:
                                navigator.userAgent.search('Mozilla') !== -1 &&
                                navigator.userAgent.search('YaBrowser') === -1 &&
                                navigator.userAgent.search('Safari') === -1 ? 'none' : 'flex'
                        }} className={styles.dateInputText}>
                            <p className={styles.dateInputLabel}>Дата</p>
                            {dateValue && <p className={styles.dateInputValue}>{dateValue}</p>}
                            <img src={ArrowDown} alt={"Выбрать"} />
                        </div>
                    </div>
                </div>
                {
                    data && chosenMethod ?
                        <Link className={styles.showMethod} to={`/statisticByMethods/${chosenMethod._id}/${dateValue}`}>
                            Сформировать статистику
                        </Link>
                        : <button className={`${styles.showMethod} ${styles.showMethodDisabled}`}>Сформировать статистику</button>
                }

            </div>
            {isLoading && <Loader />}

            {error && <div className="data-error">{error.message}</div>}

            {data && <>
                <div className={styles.contentData}>
                    {filteredMethods?.map((item, index) => {
                        return <button key={index} onClick={() => handleClick(item)}
                                       className={`${styles.contentCard} ${item === chosenMethod && styles.contentCardActive}`}>
                            <div className={styles.contentDot}>
                                {item === chosenMethod && <div className={styles.contentDotActive} />}
                            </div>
                            <p className={styles.contentText}>{item.name}</p>
                        </button>;
                    })}
                    {
                        dateValue && !filteredMethods?.length && <h3 style={{textAlign: 'center', width: '100%', marginTop: 50, marginBottom: 50}}>В эту дату не было назначений</h3>
                    }
                    {
                        !dateValue && <h3 style={{textAlign: 'center', width: '100%', marginTop: 50, marginBottom: 50}}>Выберите дату</h3>
                    }
                </div>
            </>}

        </div>
    );
};
