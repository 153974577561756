import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './ChangePasswordForm.module.scss';
import { changeAdminPassword } from 'redux/actions/setAdminsActions';

export const ChangePasswordForm = ({ id }) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();

    if (password) {
      dispatch(changeAdminPassword(id, password));
    }
  };

  const changePasswordHandler = (e) => {
    const value = e.target.value.trim();
    setPassword(value);
  };

  const showPasswordHandler = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <form className={styles.form} onSubmit={submitHandler}>
      <label htmlFor="change-password" className="label">
        Новый пароль
      </label>
      <input
        className="input"
        id="change-password"
        type={`${showPassword ? 'text' : 'password'}`}
        name="newPassword"
        value={password}
        onChange={changePasswordHandler}
        required
      />

      <div className={styles.showPassword}>
        <input
          id="show-password"
          type="checkbox"
          checked={showPassword}
          onChange={showPasswordHandler}
        />
        <label htmlFor="show-password">Показать пароль</label>
      </div>

      <div className={styles.buttons}>
        <button className="button">Изменить</button>
      </div>
    </form>
  );
};
