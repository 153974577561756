import React from 'react';
import { useParams } from 'react-router-dom';

import { ContentLayout } from 'components/layouts/ContentLayout';
import { TelegramSendMessage } from 'components/telegram/TelegramSendMessage';

const types = {
    psych: 'Психологи',
    pupil: 'Ученики'
};

export const TelegramPage = () => {
    const { slug } = useParams();

    return (
        <ContentLayout title={`Телеграм: ${types[slug]}`}>
            <TelegramSendMessage slug={slug} />
        </ContentLayout>
    );
};
