import {
    DASHBOARD_SET_MUNICIPALITY,
    DASHBOARD_SET_REGION, DASHBOARD_SET_SCHOOL
} from '../types';

export function setDashboardRegion(regionName) {
    return {
        type: DASHBOARD_SET_REGION,
        payload: regionName
    };
}
export function setDashboardMunicipality(municipality) {
    return {
        type: DASHBOARD_SET_MUNICIPALITY,
        payload: municipality
    };
}
export function setDashboardSchool(school) {
    return {
        type: DASHBOARD_SET_SCHOOL,
        payload: school
    };
}