import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContentLayout } from 'components/layouts/ContentLayout';
import {
  fetchAdmins,
  hideChangePasswordModal
} from 'redux/actions/setAdminsActions';
import { AdminsList } from 'components/admins/AdminsList';
import { Modal } from 'components/Modal';
import { ChangePasswordForm } from 'components/ChangePasswordForm';

export const AdminsListPage = () => {
  const { list, changePasswordId } = useSelector((state) => state.admins);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAdmins());
  }, [dispatch]);

  const closeChangePasswordModal = () => {
    dispatch(hideChangePasswordModal());
  };

  return (
    <>
      {changePasswordId && (
        <Modal title="Изменение пароля" onClose={closeChangePasswordModal}>
          <ChangePasswordForm id={changePasswordId} />
        </Modal>
      )}

      <ContentLayout title="Администраторы: Список">
        <AdminsList list={list} />
      </ContentLayout>
    </>
  );
};
