import React from 'react';

import { getDateTime } from 'helpers/dates';
import { ListsItemDetail } from 'components/common/ListsItemDetail';
import styles from 'static/scss/ListsItem.module.scss';

/**
 * @param {Object} item
 * @param {String} item._id
 * @param {String} item.name
 * @param {String} item.surname
 * @param {String} item.patronymic
 * @param {String} item.email
 * @param {String} item.phone
 * @param {String} item.city
 * @param {String} item.institutionType
 * @param {String} item.institution
 * @param {String} item.message
 * @param {String} item.note
 * @param {Date} item.createDate
 * @param {String} activeItem
 * @param {Function} activeItemHandler
 * @param {Function} saveNote
 */

export const RequestsItem = ({
  item,
  activeItem,
  activeItemHandler,
  saveNote
}) => {
  const {
    _id,
    email,
    name,
    surname,
    patronymic,
    phone,
    city,
    institutionType,
    institution,
    message,
    note,
    createDate
  } = item;
  const isActive = activeItem === _id;

  const onClickHandler = () => {
    activeItemHandler(_id);
  };

  return (
    <li className={styles.item}>
      <div className={styles.header}>
        <div className={styles.name}>
          <div>
            {surname} {name} {patronymic}
          </div>
          <div>
            <span>Заявка отправлена:</span>{' '}
            <span className={styles.letterSpacing}>
              {getDateTime(createDate)}
            </span>
          </div>
        </div>
        <div>
          <button className="button" onClick={onClickHandler}>
            Подробнее
          </button>
        </div>
      </div>

      {isActive && (
        <ListsItemDetail
          id={_id}
          email={email}
          phone={phone}
          city={city}
          institutionType={institutionType}
          institution={institution}
          message={message}
          note={note}
          saveNote={saveNote}
        />
      )}
    </li>
  );
};
