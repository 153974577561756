import React from 'react';

import styles from 'static/scss/ListsItem.module.scss';

const types = {
    new: 'Новый',
    active: 'Подтверждён',
    banned: 'Заблокирован',
    pilot: 'Пилот'
};

/**
 * @param {string} status
 */

export const PsychologistsItemStatus = ({ status }) => {
    const statusLower = status.toLowerCase();

    return (
        <div className={`${styles.status} ${styles[statusLower]}`}>
            <span>{types[statusLower]}</span>
        </div>
    );
};
