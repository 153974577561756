import React from 'react';
import { useDispatch } from 'react-redux';

import { getDateTime } from 'helpers/dates';
import { showChangePasswordModal } from 'redux/actions/setAdminsActions';
import styles from './AdminsListItem.module.scss';

/**
 * @param {Object} item
 * @param {String} item.id
 * @param {String} item.login
 * @param {Date} item.createDate
 // * @param {String} activeItem
 // * @param {Function} activeItemHandler
 */

export const AdminsListItem = ({ item }) => {
  const dispatch = useDispatch();
  const { _id, login, createDate } = item;

  const changePasswordHandler = () => {
    dispatch(showChangePasswordModal(_id));
  };

  return (
    <li className={styles.item}>
      <div className={styles.header}>
        <div className={styles.login}>
          <div>{login}</div>
          <div>
            <span>Аккаунт создан:</span>{' '}
            <span className={styles.letterSpacing}>
              {getDateTime(createDate)}
            </span>
          </div>
        </div>
        <div className={styles.buttons}>
          <button className="button" onClick={changePasswordHandler}>
            Изменить пароль
          </button>
        </div>
      </div>
    </li>
  );
};
