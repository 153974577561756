import React from 'react';
import styles from './StatisticByParamTable.module.scss';

const StatisticByParamTable = ({rows}) => {
    return (
        <div className={styles.table}>
            <div className={styles.headingRow}>
                <p className={styles.headingText}>Школа</p>
                <p className={styles.headingText}>Количество</p>
            </div>
            <div className={styles.rows}>
                {
                    rows?.length > 0 && rows?.map((row, index) => {
                        return <div key={index} className={styles.tableRow}>
                            <p className={styles.tableRowText}>{row?.field}</p>
                            <p className={styles.tableRowText}>{row?.value}</p>
                        </div>
                    })
                }
            </div>
        </div>
    );
};

export default StatisticByParamTable;