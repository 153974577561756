import React from 'react';
import { DashboardLayout } from '../components/layouts/DashboardLayout';
import StatisticByCategoryBlock from '../components/statistic/byCategory/StatisticByCategoryBlock';

const StatisticByCategory = () => {
    return (
        <DashboardLayout>
            <StatisticByCategoryBlock/>
        </DashboardLayout>
    );
};

export default StatisticByCategory;