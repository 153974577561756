import {
    DASHBOARD_SET_REGION,
    DASHBOARD_SET_MUNICIPALITY, DASHBOARD_SET_SCHOOL
} from './types';

const initialState = {
    currentRegion: undefined,
    currentMunicipality: {
        _id: undefined,
        name: undefined
    },
    currentSchool: {
        _id: undefined,
        name: undefined
    }
}

export const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD_SET_REGION:
            return { ...state, currentRegion: action.payload };
        case DASHBOARD_SET_MUNICIPALITY:
            return { ...state, currentMunicipality: action.payload };
        case DASHBOARD_SET_SCHOOL:
            return { ...state, currentSchool: action.payload };
        default:
            return state;
    }
}