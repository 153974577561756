import React from 'react';
import { DashboardLayout } from '../components/layouts/DashboardLayout';
import StatisticByParamBlock from '../components/statistic/byParam/StatisticByParamBlock';

const StatisticByParam = () => {
    return (
        <DashboardLayout>
            <StatisticByParamBlock/>
        </DashboardLayout>
    );
};

export default StatisticByParam;