import styles from './DashboardStatisticContent.module.scss';
import React from 'react';
import DashboardMainData from './DashboardMainData';
import DashboardGroupsData from './DashboardGroupsData';
import { Link } from 'react-router-dom';
import DashboardMethodsData from './DashboardMethodsData';
import ArrowLeftIcon from '../../../static/img/ic-arrowLeft.svg';

export const DashboardStatisticContent = () => {
    return (
        <div className={styles.dashboardStatisticContent}>
            <Link to={'/'} className={styles.backLink}>
                <img src={ArrowLeftIcon} alt="Назад"/>
                <p>Назад</p>
            </Link>
            <h2 className={styles.heading}>Статистика по регионам</h2>
            <DashboardMainData />
            <DashboardGroupsData/>
            <DashboardMethodsData/>
            <div className={styles.showStatsByMethodBlock}>
                <Link to={'/statisticByMethods'} className={styles.showStatsByMethod}>
                    Показать статистику по методикам
                </Link>
            </div>
        </div>
    );
};
