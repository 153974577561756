import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ContentLayout } from 'components/layouts/ContentLayout';
import { PsychologistsList } from 'components/psychologists/PsychologistsList';
import { fetchPsychologists } from 'redux/actions/setPsychologistsActions';

const title = {
    all: 'Психологи: Все аккаунты',
    new: 'Психологи: Новые заявки',
    active: 'Психологи: Подтверждённые аккаунты',
    banned: 'Психологи: Заблокированные аккаунты',
    pilot: 'Психологи: Пилотные аккаунты'
};

const titleSource = {
    testu: 'Testu',
    hr: 'Hr',
    univer: 'Univer'
};

export const PsychologistsListPage = ({ source = 'testu' }) => {
    const { slug } = useParams();
    const { list } = useSelector((state) => state.psychologists);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPsychologists(slug, source));
    }, [slug, source, dispatch]);

    return (
        <ContentLayout title={`${titleSource[source]} / ${title[slug]}`}>
            <PsychologistsList list={list} source={source} />
        </ContentLayout>
    );
};
