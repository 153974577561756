import axios from 'axios';

export const fetchGeneralStatistic = async () => {
    try {
        const response = await axios.get('statistic/admin/general');

        return response.data;
    } catch (e) {
        const message = e.response?.data?.message || 'Ошибка загрузки данных';

        // eslint-disable-next-line no-throw-literal
        throw {
            message
        };
    }
};
export const fetchDashboardStatistic = async (regionName, municipalityId, schoolId) => {
    try {
        const response = await axios.post('statistic/admin/dashboard', { regionName, municipalityId, schoolId });

        return response.data;
    } catch (e) {
        const message = e.response?.data?.message || 'Ошибка загрузки данных';

        // eslint-disable-next-line no-throw-literal
        throw {
            message
        };
    }
};


export const fetchDashboardCategories = async (regionName, municipalityId, schoolId) => {
    try {
        const response = await axios.post('statistic/admin/dashboard/categories', {regionName, municipalityId, schoolId});

        return response.data;
    } catch (e) {
        const message = e.response?.data?.message || 'Ошибка загрузки данных';

        // eslint-disable-next-line no-throw-literal
        throw {
            message
        };
    }
};

export const fetchDashboardMethods = async (regionName, municipalityId, schoolId) => {
    try {
        const response = await axios.post('statistic/admin/dashboard/methods', {regionName, municipalityId, schoolId});

        return response.data;
    } catch (e) {
        const message = e.response?.data?.message || 'Ошибка загрузки данных';

        // eslint-disable-next-line no-throw-literal
        throw {
            message
        };
    }
};
export const fetchDashboardMethodsByDate = async (date, regionName, municipalityId, schoolId) => {
    try {
        const response = await axios.post('statistic/admin/dashboard/methodsByDate', {date, regionName, municipalityId, schoolId});

        return response.data;
    } catch (e) {
        const message = e.response?.data?.message || 'Ошибка загрузки данных';

        // eslint-disable-next-line no-throw-literal
        throw {
            message
        };
    }
};
export const fetchDashboardMethodStats = async (date, conditionId) => {
    try {
        const response = await axios.post('statistic/admin/dashboard/methodStat', {date, conditionId});

        return response.data;
    } catch (e) {
        const message = e.response?.data?.message || 'Ошибка загрузки данных';

        // eslint-disable-next-line no-throw-literal
        throw {
            message
        };
    }
};
export const fetchDashboardMethodReport = async (conditionId, date, filters) => {
    try {
        const response = await axios.post(
            'statistic/admin/dashboard/methodReport',
            {conditionId, date, filters}, {
                responseType: 'blob'
            });

        const data = await response.data;
        const href = URL.createObjectURL(data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'report.xlsx'); //or any other extension

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(href);

        return response.data;
    } catch (e) {
        const message = e.response?.data?.message || 'Ошибка загрузки данных';

        throw {
            message
        };
    }
};

export const fetchStatisticByParam = async (param, period, regionName, municipalityId, schoolId) => {
    try {
        const response = await axios.post('statistic/admin/dashboard/byParam', {param, period, regionName, municipalityId, schoolId});

        return response.data;
    } catch (e) {
        const message = e.response?.data?.message || 'Ошибка загрузки данных';

        // eslint-disable-next-line no-throw-literal
        throw {
            message
        };
    }
};
export const fetchStatisticByCategory = async (categoryId, regionName, municipalityId, schoolId) => {
    try {
        const response = await axios.post('statistic/admin/dashboard/byCategory', {categoryId, regionName, municipalityId, schoolId});

        return response.data;
    } catch (e) {
        const message = e.response?.data?.message || 'Ошибка загрузки данных';

        // eslint-disable-next-line no-throw-literal
        throw {
            message
        };
    }
};
export const fetchStatisticByMethod = async (methodId, period, regionName, municipalityId, schoolId) => {
    try {
        const response = await axios.post('statistic/admin/dashboard/byMethod', {methodId, period, regionName, municipalityId, schoolId});

        return response.data;
    } catch (e) {
        const message = e.response?.data?.message || 'Ошибка загрузки данных';

        // eslint-disable-next-line no-throw-literal
        throw {
            message
        };
    }
};