import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import TextareaAutosize from 'react-textarea-autosize';

import {
    finishLoading,
    showAlert,
    startLoading
} from 'redux/actions/setAppActions';
import styles from './TelegramSendMessage.module.scss';

const textTarget = {
    psych: 'психологам',
    pupil: 'ученикам'
};

export const TelegramSendMessage = ({ slug }) => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const [isSending, setIsSending] = useState(false);

    useEffect(() => {
        setMessage('')
    }, [slug])

    const changeMessageHandler = (e) => {
        setMessage(e.target.value);
    };

    const sendMessageHandler = async () => {
        if (message.trim() && !isSending) {
            try {
                setIsSending(true);
                dispatch(startLoading());

                await axios.post(`${slug}/newsletter`, {
                    text: message
                });

                dispatch(
                    showAlert({
                        type: 'success',
                        text: 'Сообщение успешно разослано'
                    })
                );
            } catch (err) {
                const text =
                    err.response?.data?.message ||
                    err.message ||
                    'Ошибка при рассылке сообщения';
                dispatch(showAlert({ type: 'error', text }));
            } finally {
                setMessage('')
                setIsSending(false);
                dispatch(finishLoading());
            }
        }
    };

    return (
        <div>
            <span>Сообщение {textTarget[slug]}:</span>
            <div>
                <TextareaAutosize
                    className="textarea-autosize"
                    value={message}
                    onChange={changeMessageHandler}
                    name="message"
                    placeholder={`Введите текст сообщения для рассылки ${textTarget[slug]}`}
                    minRows={5}
                    disabled={isSending}
                />
            </div>

            <div className={styles.btnWrapper}>
                <button
                    className="button"
                    onClick={sendMessageHandler}
                    disabled={isSending}
                >
                    Отправить
                </button>
            </div>
        </div>
    );
};
