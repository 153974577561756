import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { RequestsItem } from 'components/requests/RequestsItem';
import { ListPagesBlock } from 'components/ListPagesBlock';
import { updateRequest } from 'redux/actions/setRequestsActions';

export const RequestsList = ({ list }) => {
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState('');
  const [slicedList, setSlicedList] = useState([]);
  const [pagesCount, setPagesCount] = useState(1);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (list) {
      const pages = Math.ceil(list.length / 30);
      setPagesCount(pages);

      if (pages > 1) {
        setSlicedList([...list].slice(page * 30, page * 30 + 30));
      } else {
        setSlicedList(list);
      }
    }
  }, [list, page]);

  const activeItemHandler = (id) => {
    if (id !== activeItem) {
      setActiveItem(id);
    } else {
      setActiveItem('');
    }
  };

  const selectPage = (selectedPage) => {
    window.scrollTo({ top: 0 });
    setPage(selectedPage);
  };

  const saveNote = (id, note) => {
    dispatch(updateRequest(id, { note }));
  };

  return (
    <>
      {!!slicedList?.length ? (
        <ul className="list-wrapper">
          {slicedList.map((item) => (
            <RequestsItem
              key={item._id}
              item={item}
              activeItem={activeItem}
              activeItemHandler={activeItemHandler}
              saveNote={saveNote}
            />
          ))}
        </ul>
      ) : (
        <div className="not-found-text">Ничего не найдено</div>
      )}

      {pagesCount > 1 && (
        <ListPagesBlock pagesCount={pagesCount} selectPage={selectPage} />
      )}
    </>
  );
};
