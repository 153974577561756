import axios from 'axios';

import { COMMENTS_LIST, COMMENTS_UPDATE } from '../types';
import {
  finishLoading,
  showAlert,
  startLoading
} from '../actions/setAppActions';

export function fetchComments() {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const { data } = await axios.get('suggestions');
      dispatch({ type: COMMENTS_LIST, payload: data });
    } catch (e) {
      const text = e.response?.data?.message || e.message;
      dispatch(showAlert({ type: 'error', text }));
    } finally {
      dispatch(finishLoading());
    }
  };
}

export function updateComment(id, body) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const { data } = await axios.put(`suggestions/${id}`, body);

      dispatch({
        type: COMMENTS_UPDATE,
        payload: {
          id,
          updatedCommit: data
        }
      });
    } catch (e) {
      const text = e.response?.data?.message || e.message;
      dispatch(showAlert({ type: 'error', text }));
    } finally {
      dispatch(finishLoading());
    }
  };
}
