import { PSYCHOLOGISTS_LIST, PSYCHOLOGISTS_UPDATE } from './types';

const initialState = {
    list: null
};

export const psychologistsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PSYCHOLOGISTS_LIST:
            return { ...state, list: action.payload };
        case PSYCHOLOGISTS_UPDATE: {
            const { userId, updatedUser } = action.payload;
            const newList = [...state.list];
            const index = newList.findIndex(({ _id }) => _id === userId);

            if (index !== -1) {
                if (newList[index].status === updatedUser.status) {
                    newList.splice(index, 1, updatedUser);
                } else {
                    newList.splice(index, 1);
                }
            }

            return { ...state, list: newList };
        }
        default:
            return state;
    }
};
