import {
  FINISH_LOADING,
  SHOW_ALERT,
  HIDE_ALERT,
  START_LOADING
} from '../types';

export function startLoading() {
  return {
    type: START_LOADING
  };
}

export function finishLoading() {
  return {
    type: FINISH_LOADING
  };
}

export function showAlert(alert) {
  return (dispatch) => {
    dispatch({
      type: SHOW_ALERT,
      payload: alert
    });

    setTimeout(() => {
      dispatch(hideAlert());
    }, 3001);
  };
}

export function hideAlert() {
  return {
    type: HIDE_ALERT
  };
}
