import React from 'react';

import styles from './ListPagesBlock.module.scss';

export const ListPagesBlock = ({ pagesCount, selectPage }) => {
  const onClickHandler = (page) => {
    selectPage(page);
  };

  return (
    <div className={styles.wrapper}>
      {Array.from({ length: pagesCount }, (_, i) => (
        <button className="button" onClick={() => onClickHandler(i)} key={i}>
          {i + 1}
        </button>
      ))}
    </div>
  );
};
