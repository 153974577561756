import React, { useEffect, useState } from 'react';
import styles from './DashboardFilters.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDownIcon from '../../../static/img/ic-arrowDown.svg'
import {
    setDashboardMunicipality,
    setDashboardRegion,
    setDashboardSchool
} from '../../../redux/actions/setDashboardActions';

const DashboardFilters = ({regions, municipalities, schools}) => {
    const { admin } = useSelector((state) => state.admin);
    const dashboard = useSelector((state) => state.dashboard);
    const dispatch = useDispatch();

    const [isRGOpened, setIsRGOpened] = useState(false);
    const [isMCOpened, setIsMCOpened] = useState(false);
    const [isSCOpened, setIsSCOpened] = useState(false);
    const [filteredSchools, setFilteredSchools] = useState([]);
    const [filterText, setFilterText] = useState('');

    const changeRegion = async (regionName) => {
        dispatch(setDashboardRegion(regionName))
    }
    const changeMunicipality = async (municipality) => {
        dispatch(setDashboardMunicipality(municipality));
    }
    const changeSchool = async (school) => {
        dispatch(setDashboardSchool(school));
    }
    const handleRGFilterOpen = () => {
        setIsMCOpened(false);
        setIsSCOpened(false);
        if(admin?.type !== "onlyMunicipality"){
            setIsRGOpened(state => !state);
        }
    }
    const handleMCFilterOpen = () => {
        setIsRGOpened(false);
        setIsSCOpened(false);
        if(admin?.type !== "onlyMunicipality") {
            setIsMCOpened(state => !state);
        }
    }
    const handleSCFilterOpen = () => {
        setIsRGOpened(false);
        setIsMCOpened(false);
        setIsSCOpened(state => !state);
    }
    const handleFilterTextChange = (e) => {
        setFilterText(e.target.value);
    }

    useEffect(() => {
        if(filterText === '') {
            setFilteredSchools(schools)
            return
        }

        const newFilteredSchools = []
        for(let school of schools){
            if(school.name.toLowerCase().includes(filterText.toLowerCase())){
                newFilteredSchools.push(school);
            }
        }

        setFilteredSchools(newFilteredSchools)
    }, [schools, filterText]);

    return (
        <div className={styles.filters}>
            <button onClick={handleRGFilterOpen} tabIndex={0} className={styles.timeframeFilter}>
                <p>
                    {dashboard.currentRegion || "Все регионы"}
                </p>
                <img src={ArrowDownIcon} alt="Открыть" />
                {
                    isRGOpened && <div className={styles.timeframeFilterContent}>
                        <button onClick={() => changeRegion(null)}>{"Все регионы"}</button>
                        {
                            regions.map((item, index) => item &&
                                <button key={index} onClick={() => changeRegion(item)}>{item}</button>)
                        }
                    </div>
                }
            </button>
            {
                municipalities && dashboard.currentRegion && <button onClick={handleMCFilterOpen} tabIndex={0} className={styles.timeframeFilter}>
                    <p>
                        {dashboard.currentMunicipality?.name || 'Все муниципалитеты'}
                    </p>
                    <img src={ArrowDownIcon} alt="Открыть" />
                    {
                        isMCOpened && <div className={styles.timeframeFilterContent}>
                            <button onClick={() => changeMunicipality(null)}>{'Все муниципалитеты'}</button>
                            {
                                municipalities.map((item, index) => item &&
                                    <button key={index} onClick={() => changeMunicipality(item)}>{item.name}</button>)
                            }
                        </div>
                    }
                </button>
            }
            {
                filteredSchools && dashboard.currentMunicipality?._id && <>
                    <button onClick={handleSCFilterOpen} tabIndex={0} className={styles.timeframeFilter}>
                        <p>
                            {dashboard.currentSchool?.name || 'Все школы'}
                        </p>
                        <img src={ArrowDownIcon} alt="Открыть" />
                        {
                            isSCOpened && <div className={styles.timeframeFilterContent}>
                                <button onClick={() => changeSchool(null)}>{'Все школы'}</button>
                                {
                                    filteredSchools?.map((item, index) => item &&
                                        <button key={index} onClick={() => changeSchool(item)}>{item.name}</button>)
                                }
                            </div>
                        }
                    </button>
                    {
                        isSCOpened && <input value={filterText} onChange={handleFilterTextChange} placeholder={'Поиск школы'} />
                    }
                </>
            }
        </div>
    );
};

export default DashboardFilters;