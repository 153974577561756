import React, { useMemo } from 'react';
import styles from './ByMethodTable.module.scss';

const ByMethodTable = ({scalesData}) => {
    const colors = ['#219653', '#E42036', '#2F80ED', '#FECC62', '#812FED']

    const maxLevel = useMemo(() => {
        let max = 0;

        scalesData?.forEach(scale => {
            if(max < scale.levels.length){
                max = scale.levels.length;
            }
        })

        return max
    }, [scalesData])

    return (
        <div className={styles.byMethodTable}>
            <div className={styles.tableHeader}>
                <p className={styles.tableHeaderText}>Наименование шкалы</p>
                <div className={styles.tableHeaderNames}>
                    {
                        Array(maxLevel).fill(0).map((item, index) => {
                            return <div className={styles.tableHeaderName}>
                                <div style={{ backgroundColor: colors[index] }} className={styles.tableHeaderNameDot} />
                                <p className={styles.tableHeaderNameText}>Уровень {index + 1}</p>
                            </div>;
                        })
                    }

                </div>
            </div>
            {
                scalesData?.map((scale, i) => {
                    return <div key={i} className={styles.tableContentRow}>
                        <p className={styles.tableContentRowHeading}>{scale.name}</p>
                        <div className={styles.tableContentRowData}>
                            {
                                scale.levels.map((level, index) => {
                                    return <p key={index} className={styles.tableContentRowValue}>{level}</p>;
                                })
                            }
                        </div>
                    </div>
                })
            }

        </div>
    );
};

export default ByMethodTable;