import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { fetchGeneralStatistic } from 'api';
import { ContentLayout } from 'components/layouts/ContentLayout';
import { Loader } from 'components/Loader';
import { GeneralStatisticContent } from 'components/statistic/GeneralStatisticContent';

export const StatisticPage = () => {
    const { data, error, isLoading } = useQuery({
        queryKey: ['statistic'],
        queryFn: fetchGeneralStatistic
    });

    return (
        <ContentLayout title="Статистика: Общая">
            {isLoading && <Loader />}

            {error && <div className="data-error">{error.message}</div>}

            {data && <GeneralStatisticContent data={data} />}
        </ContentLayout>
    );
};
