import axios from 'axios';

import { REQUESTS_LIST, REQUESTS_UPDATE } from '../types';
import {
  finishLoading,
  showAlert,
  startLoading
} from '../actions/setAppActions';

export function fetchRequests() {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const { data } = await axios.get('requests/all');
      dispatch({ type: REQUESTS_LIST, payload: data });
    } catch (e) {
      const text = e.response?.data?.message || e.message;
      dispatch(showAlert({ type: 'error', text }));
    } finally {
      dispatch(finishLoading());
    }
  };
}

export function updateRequest(id, body) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const { data } = await axios.put(`requests/${id}`, body);

      dispatch({
        type: REQUESTS_UPDATE,
        payload: {
          id,
          updatedRequest: data
        }
      });
    } catch (e) {
      const text = e.response?.data?.message || e.message;
      dispatch(showAlert({ type: 'error', text }));
    } finally {
      dispatch(finishLoading());
    }
  };
}
