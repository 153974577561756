import React, { useState } from 'react';
import { DashboardLayout } from '../components/layouts/DashboardLayout';
import { ByMethodStatisticContent } from '../components/statistic/byMethod/ByMethodStatisticContent';
import { ByMethodPagination } from '../components/statistic/byMethod/ByMethodPagination';

export const StatisticByMethodChoosePage = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    return (
        <DashboardLayout>
            <ByMethodStatisticContent
                currentPage={currentPage}
                setTotalPages={setTotalPages}
                setCurrentPage={setCurrentPage}
            />
            <ByMethodPagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
            />
        </DashboardLayout>
    );
};
