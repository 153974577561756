import React from 'react';
import { DashboardLayout } from '../components/layouts/DashboardLayout';
import ByMethodStatisticData from '../components/statistic/byMethod/ByMethodStatisticData';

export const StatisticByMethod = () => {
    return (
        <DashboardLayout>
            <ByMethodStatisticData />
        </DashboardLayout>
    );
};
