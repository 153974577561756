import { REQUESTS_LIST, REQUESTS_UPDATE } from './types';

const initialState = {
  list: null
};

export const requestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUESTS_LIST:
      return { ...state, list: action.payload };
    case REQUESTS_UPDATE: {
      const { id, updatedRequest } = action.payload;
      const index = state.list.findIndex(({ _id }) => _id === id);
      const newList = [...state.list];

      newList.splice(index, 1, updatedRequest);

      return { ...state, list: newList };
    }
    default:
      return state;
  }
};
