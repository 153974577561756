import React from 'react';
import axios from 'axios';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { rootReducer } from 'redux/rootReducer';
import App from 'App';
import './static/scss/index.scss';

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api/`;
axios.defaults.withCredentials = true;

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: false
        }
    }
});

const app = (
    <React.StrictMode>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </Provider>
    </React.StrictMode>
);

render(app, document.getElementById('root'));
