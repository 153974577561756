import axios from 'axios';

import { ADMIN_LOGOUT } from '../types';
import {
    startLoading,
    finishLoading,
    showAlert
} from '../actions/setAppActions';

// export function authAdmin() {
//   return async (dispatch) => {
//     try {
//       dispatch(startLoading());
//       const { data } = await axios.get('admin/auth');
//
//       dispatch({ type: ADMIN_AUTH, payload: data });
//       dispatch(finishLoading());
//     } catch (e) {
//     } finally {
//       dispatch(finishLoading());
//     }
//   };
// }

// export function loginAdmin(adminData) {
//   return async (dispatch) => {
//     try {
//       dispatch(startLoading());
//       const { data } = await axios.post('admin/login', adminData);
//
//       dispatch({ type: ADMIN_AUTH, payload: data });
//     } catch (e) {
//       const text = e.response?.data?.message || e.message;
//       dispatch(showAlert({ type: 'error', text }));
//     } finally {
//       dispatch(finishLoading());
//     }
//   };
// }

export function logoutAdmin() {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            await axios.get('admin/logout');
            dispatch({ type: ADMIN_LOGOUT });
        } catch (e) {
            const text = e.response?.data?.message || e.message;
            dispatch(showAlert({ type: 'error', text }));
        } finally {
            dispatch(finishLoading());
        }
    };
}
