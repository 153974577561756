import React from 'react';
import styles from './DashboardGroupsData.module.scss';
import { useQuery } from '@tanstack/react-query';
import { fetchDashboardCategories } from '../../../api/fetchGeneralStatistic';
import { Loader } from '../../Loader';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const DashboardGroupsData = () => {
    const colors = [
        '#2F80ED',
        '#D97F15',
        '#ACD044',
        '#AE13C8',
        '#FECC62',
        '#C9AD1C',
        '#E3008B',
        '#E42036',
        '#3C3990',
        '#66B6C1',
        '#F7EE17',
        '#219653',
        '#DF4E20',
        '#1445C4'
    ]

    const dashboard = useSelector(state => state.dashboard)
    const { data, error, isLoading } = useQuery({
        queryKey: ['statistic/dashboard/categories', dashboard],
        queryFn: async () => {
            return await fetchDashboardCategories(dashboard.currentRegion, dashboard.currentMunicipality?._id, dashboard.currentSchool?._id)
        }
    });

    function getRandomHexColor(index) {
        if(index < 14){
            return colors[index];
        }
        return '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
    }

    return (
        <div className={styles.groupsDataBlock}>
            {isLoading && <Loader />}

            {error && <div className="data-error">{error.message}</div>}

            {data && <>
                <p className={styles.groupsDataHeading}>Количество детей в Целевых группах</p>
                <div className={styles.groupsDataCounter}>
                    <p className={styles.groupsDataCounterText}>{data?.total}</p>
                </div>
                <div className={styles.groupsDataTable}>
                    <div className={styles.groupsDataTableHeading}>
                        <p className={styles.groupsDataTableHeadingText}>Направление</p>
                        <p className={styles.groupsDataTableHeadingText}>Количество</p>
                    </div>
                    <div className={styles.groupsDataTableContent}>
                        {
                            data?.items?.map((item, index) => {
                                return <Link key={index} to={'/statistic/byCategory/' + item.categoryId} className={styles.groupsDataTableLine}>
                                    <div className={styles.groupsDataTableLineNameBlock}>
                                        <div style={{ backgroundColor: getRandomHexColor(index) }}
                                             className={styles.groupsDataTableLineColor} />
                                        <p className={styles.groupsDataTableLineName}>{item.title}</p>
                                    </div>
                                    <p className={styles.groupsDataTableLineCounter}>{item.count}</p>
                                </Link>;
                            })
                        }
                    </div>

                </div>
            </>}
        </div>
    );
};

export default DashboardGroupsData;