import React from 'react';
import styles from './StatisticByCategoryTable.module.scss';

const StatisticByCategoryTable = ({rows}) => {
    return (
        <div className={styles.table}>
            <div className={styles.headingRow}>
                <p style={{
                    textAlign: 'left'
                }} className={styles.headingText}>Идентификатор ученика</p>
                <p className={styles.headingText}>Школа</p>
                <p className={styles.headingText}>Класс</p>
                <p style={{
                    textAlign: 'right'
                }} className={styles.headingText}>Психолог</p>
            </div>
            <div className={styles.rows}>
                {
                    rows?.length > 0 && rows?.map((row, index) => {
                        return <div key={index} className={styles.tableRow}>
                            <p style={{
                                justifyContent: 'flex-start',
                                textAlign: 'left'
                            }} className={styles.tableRowText}>{row?.pupilId}</p>
                            <p className={styles.tableRowText}>{row?.school}</p>
                            <p className={styles.tableRowText}>{row?.class}</p>
                            <p style={{
                                justifyContent: 'flex-end',
                                textAlign: 'right'
                            }} className={styles.tableRowText}>{row?.psych}</p>
                        </div>
                    })
                }
            </div>
        </div>
    );
};

export default StatisticByCategoryTable;