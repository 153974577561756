import React, { useEffect, useState } from 'react';
import { useDebounce } from 'hooks/useDebounce';

import crossIcon from 'static/img/cross.svg';
import styles from 'components/psychologists/PsychologistsSearch.module.scss';

export const PsychologistsSearch = ({ setFilterRegexHandler }) => {
    const [searchField, setSearchField] = useState('');
    const debouncedValue = useDebounce(searchField, 700);

    const searchChangeHandler = (e) => {
        setSearchField(e.target.value);
    };

    const searchChangeClear = () => {
        setSearchField('');
    };

    useEffect(() => {
        if (debouncedValue.trim()) {
            const regex = new RegExp(debouncedValue, 'i');

            setFilterRegexHandler(regex);
        } else {
            setFilterRegexHandler('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    return (
        <div className={styles.searchBlock}>
            <div className={styles.inputWrapper}>
                <input
                    type="text"
                    placeholder="Поиск по ФИО или городу"
                    value={searchField}
                    onChange={searchChangeHandler}
                    className={styles.input}
                />
                {searchField && (
                    <img src={crossIcon} alt="" onClick={searchChangeClear} />
                )}
            </div>
        </div>
    );
};
