export const getDateTime = (date) => {
  if (!date) {
    return '--';
  }

  return new Date(date).toLocaleDateString('ru-RU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  });
};

export const getDate = (date) => {
  if (!date) {
    return '--';
  }

  return new Date(date).toLocaleDateString('ru-RU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  });
};
