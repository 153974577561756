import React from 'react';
import styles from './StatisticByMethodBlock.module.scss';
import { Link, useParams } from 'react-router-dom';
import ArrowLeftIcon from '../../../static/img/ic-arrowLeft.svg';
import { useQuery } from '@tanstack/react-query';
import { fetchStatisticByMethod } from '../../../api/fetchGeneralStatistic';
import { useSelector } from 'react-redux';
import StatisticByMethodTable from './StatisticByMethodTable';
import { Loader } from '../../Loader';

const StatisticByMethodBlock = () => {
    const {methodId} = useParams();
    const {period} = useParams();
    const dashboard = useSelector(state => state.dashboard)

    const { data, error, isLoading } = useQuery({
        queryKey: ['statistic/' + methodId, period, dashboard.currentRegion, dashboard.currentMunicipality?._id, dashboard?.currentSchool?._id],
        queryFn: async () => {
            console.log(dashboard.currentMunicipality?._id)
            return await fetchStatisticByMethod(methodId, period, dashboard.currentRegion, dashboard.currentMunicipality?._id, dashboard?.currentSchool?._id)
        }
    });

    return (
        <>
            {isLoading && <Loader />}

            {error && <div className="data-error">{error.message}</div>}

            {data && <div className={styles.statisticByParamBlock}>
                <Link to={'/statisticDashboard'} className={styles.backLink}>
                    <img src={ArrowLeftIcon} alt="Назад" />
                    <p>Назад</p>
                </Link>

                <h2 className={styles.heading}>Статистика по методике</h2>
                <StatisticByMethodTable rows={data} />
            </div>}
        </>

    );
};

export default StatisticByMethodBlock;