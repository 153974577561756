import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchRequests } from 'redux/actions/setRequestsActions';
import { ContentLayout } from 'components/layouts/ContentLayout';
import { RequestsList } from 'components/requests/RequestsList';

export const RequestsListPage = () => {
  const { list } = useSelector((state) => state.requests);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRequests());
  }, [dispatch]);

  return (
    <ContentLayout title="Заявки: Список">
      <RequestsList list={list} />
    </ContentLayout>
  );
};
