import React from 'react';
import { DashboardStatisticContent } from '../components/statistic/dashboard/DashboardStatisticContent';
import { DashboardLayout } from '../components/layouts/DashboardLayout';

export const StatisticDashboardPage = () => {
    return (
        <DashboardLayout>
            <DashboardStatisticContent/>
        </DashboardLayout>
    );
};
