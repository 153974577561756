import { ADMIN_AUTH, ADMIN_LOGOUT } from './types';

const initialState = {
  admin: null
};

export const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_AUTH:
      const { login, type, municipality, region } = action.payload;
      return { ...state, admin: { login, type, municipality, region } };
    case ADMIN_LOGOUT:
      return { ...state, admin: null };
    default:
      return state;
  }
};
