import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchComments } from 'redux/actions/setCommentsActions';
import { ContentLayout } from 'components/layouts/ContentLayout';
import { CommentsList } from 'components/comments/CommentsList';

const types = {
  suggestion: 'Предложения',
  problem: 'Технические проблемы'
};

export const CommentsPage = () => {
  const { slug } = useParams();
  const { list } = useSelector((state) => state.comments);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchComments());
  }, [dispatch]);

  return (
    <ContentLayout title={`Отзывы: ${types[slug]}`}>
      {list && <CommentsList list={list.filter(({ type }) => type === slug)} />}
    </ContentLayout>
  );
};
