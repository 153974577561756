import React, { useState } from 'react';
import styles from './DashboardMainData.module.scss';
import { useQuery } from '@tanstack/react-query';
import { fetchDashboardStatistic } from '../../../api/fetchGeneralStatistic';
import { Loader } from '../../Loader';
import DashboardTotalData from './DashboardTotalData';
import DashboardFilters from './DashboardFilters';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const DashboardMainData = () => {
    const [currentTimeframe, setCurrentTimeframe] = useState("total");
    const dashboard = useSelector((state) => state.dashboard)

    const changeTimeframe = (timeframe) => {
        setCurrentTimeframe(timeframe);
    }

    const { data, error, isLoading } = useQuery({
        queryKey: ['statistic/dashboard', dashboard],
        queryFn: async () => {
            return await fetchDashboardStatistic(dashboard.currentRegion, dashboard?.currentMunicipality?._id, dashboard?.currentSchool?._id)
        }
    });

    const {
        psychs: {
            psychsTotal,
            psychsActive,
        },
        pupils: {
            pupilsTotal,
            pupilsWithTests,
        },
        alarms,
        finishedMeetings,
        advices,
        regions,
        municipalities,
        schools
    } = data || { psychs: {}, pupils: {} };


    return (
        <>
            {data && <DashboardFilters
                regions={regions}
                municipalities={municipalities}
                schools={schools}
            />}
            {data && <DashboardTotalData pupilsTotal={pupilsTotal?.total} psychsTotal={psychsTotal?.total} />}
            <div className={styles.mainDataBlock}>
                {isLoading && <Loader />}

                {error && <div className="data-error">{error.message}</div>}

                {data && <>
                    <div className={styles.mainDataHeader}>
                        <p className={styles.mainDataHeaderText}>Показатели за период времени</p>
                        <div className={styles.periodFilterBlock}>
                            <button onClick={() => changeTimeframe('today')}
                                    className={`${styles.periodFilter} ${currentTimeframe === 'today' && styles.periodFilterActive}`}>За
                                сегодня
                            </button>
                            <button onClick={() => changeTimeframe('month')}
                                    className={`${styles.periodFilter} ${currentTimeframe === 'month' && styles.periodFilterActive}`}>За
                                месяц
                            </button>
                            <button onClick={() => changeTimeframe('year')}
                                    className={`${styles.periodFilter} ${currentTimeframe === 'year' && styles.periodFilterActive}`}>За
                                год
                            </button>
                            <button onClick={() => changeTimeframe('total')}
                                    className={`${styles.periodFilter} ${currentTimeframe === 'total' && styles.periodFilterActive}`}>За
                                всё время
                            </button>
                        </div>
                    </div>
                    <div className={styles.mainDataContent}>
                        <Link to={'/statistic/byParam/activePsychs/' + currentTimeframe} className={styles.mainDataCard}>
                            <div className={styles.mainDataCardTextBlock}>
                                <p className={styles.mainDataCardTextHeading}>Активных психологов</p>
                                <p className={styles.mainDataCardTextInfo}>заходили в личный кабинет</p>
                            </div>
                            <p className={styles.mainDataCardValue}>{psychsActive[currentTimeframe] || 0}</p>
                        </Link>
                        <Link  to={'/statistic/byParam/studentsPassedTest/' + currentTimeframe} className={styles.mainDataCard}>
                            <div className={styles.mainDataCardTextBlock}>
                                <p className={styles.mainDataCardTextHeading}>Учеников, прошедших тестирование</p>
                            </div>
                            <p className={styles.mainDataCardValue}> {pupilsWithTests[currentTimeframe] || 0}</p>
                        </Link>
                        <Link to={'/statistic/byParam/alarms/' + currentTimeframe} className={styles.mainDataCard}>
                            <div className={styles.mainDataCardTextBlock}>
                                <p className={styles.mainDataCardTextHeading}>Запросов на разговор от детей к
                                    психологу</p>
                            </div>
                            <p className={styles.mainDataCardValue}>{alarms[currentTimeframe] || 0}</p>
                        </Link>
                        <Link to={'/statistic/byParam/meetings/' + currentTimeframe} className={styles.mainDataCard}>
                            <div className={styles.mainDataCardTextBlock}>
                                <p className={styles.mainDataCardTextHeading}>Назначенных встреч</p>
                            </div>
                            <p className={styles.mainDataCardValue}>{finishedMeetings[currentTimeframe] || 0}</p>
                        </Link>
                        <Link to={'/statistic/byParam/advices/' + currentTimeframe} className={styles.mainDataCard}>
                            <div className={styles.mainDataCardTextBlock}>
                                <p className={styles.mainDataCardTextHeading}>Отправленных рекомендаций</p>
                            </div>
                            <p className={styles.mainDataCardValue}>{advices[currentTimeframe] || 0}</p>
                        </Link>
                    </div>
                </>}
            </div>
        </>

    );
};

export default DashboardMainData;