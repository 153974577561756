import { ADMINS_CHANGE_PASSWORD, ADMINS_LIST } from './types';

const initialState = {
  list: null,
  changePasswordId: null
};

export const adminsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMINS_LIST:
      return { ...state, list: action.payload };
    case ADMINS_CHANGE_PASSWORD:
      return { ...state, changePasswordId: action.payload };
    default:
      return state;
  }
};
