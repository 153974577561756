import { ListsItemDetail } from 'components/common/ListsItemDetail';
import { getDateTime } from 'helpers/dates';
import dnevnikLogo from 'static/img/dnevnik_logotype.png';
import styles from 'static/scss/ListsItem.module.scss';
import { PsychologistsItemStatus } from './PsychologistsItemStatus';

/**
 * @param {Object} item
 * @param {String} item._id
 * @param {String} item.name
 * @param {String} item.surname
 * @param {String} item.patronymic
 * @param {String} item.email
 * @param {String} item.login
 * @param {String} item.status
 * @param {String} item.city
 * @param {String} item.phone
 * @param {String} item.institution
 * @param {String} item.birthday
 * @param {String} item.lastLogin
 * @param {String} item.note
 * @param {String=} item.dnevnikruPersonId
 * @param {Date} item.createDate
 * @param {Date} item.updateDate
 * @param {String} activeItem
 * @param {Function} activeItemHandler
 * @param {Function} saveNote
 * @param {String} source
 */

export const PsychologistsItem = ({
    item,
    activeItem,
    activeItemHandler,
    saveNote,
    source = 'testu'
}) => {
    const {
        _id,
        email,
        login,
        name,
        surname,
        patronymic,
        status,
        city,
        phone,
        institution,
        birthday,
        note,
        dnevnikruPersonId,
        createDate,
        updateDate,
        lastLogin
    } = item;
    const isActive = activeItem === _id;

    const onClickHandler = () => {
        activeItemHandler(_id);
    };

    return (
        <li className={styles.item}>
            <div className={styles.header}>
                <div className={styles.name}>
                    <div>
                        {surname} {name} {patronymic}
                    </div>
                    <div>
                        <span>Заявка создана:</span>{' '}
                        <span className={styles.letterSpacing}>
                            {getDateTime(createDate)}
                        </span>
                    </div>
                    <div>
                        <span>Заявка обновлена:</span>{' '}
                        <span className={styles.letterSpacing}>
                            {getDateTime(updateDate)}
                        </span>
                    </div>
                </div>

                {dnevnikruPersonId && (
                    <img
                        src={dnevnikLogo}
                        width={70}
                        style={{ alignSelf: 'flex-start', marginTop: 10 }}
                        alt="Дневник.ру"
                    />
                )}

                <PsychologistsItemStatus status={status} />
                <div>
                    <button className="button" onClick={onClickHandler}>
                        Подробнее
                    </button>
                </div>
            </div>

            {isActive && (
                <ListsItemDetail
                    id={_id}
                    email={email}
                    phone={phone}
                    login={login}
                    city={city}
                    institution={institution}
                    birthday={birthday}
                    lastLogin={lastLogin}
                    status={status}
                    note={note}
                    dnevnikruPersonId={dnevnikruPersonId}
                    saveNote={saveNote}
                    source={source}
                    isShowLastLogin
                />
            )}
        </li>
    );
};
