import React, { useEffect, useState } from 'react';

import { AdminsListItem } from 'components/admins/AdminsListItem';
import { ListPagesBlock } from 'components/ListPagesBlock';

export const AdminsList = ({ list }) => {
  const [slicedList, setSlicedList] = useState([]);
  const [pagesCount, setPagesCount] = useState(1);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (list) {
      const pages = Math.ceil(list.length / 30);
      setPagesCount(pages);

      if (pages > 1) {
        setSlicedList([...list].slice(page * 30, page * 30 + 30));
      } else {
        setSlicedList(list);
      }
    }
  }, [list, page]);

  const selectPage = (selectedPage) => {
    window.scrollTo({ top: 0 });
    setPage(selectedPage);
  };

  return (
    <>
      {!!slicedList?.length ? (
        <ul className="list-wrapper">
          {slicedList.map((item) => (
            <AdminsListItem key={item._id} item={item} />
          ))}
        </ul>
      ) : (
        <div className="not-found-text">Ничего не найдено</div>
      )}

      {pagesCount > 1 && (
        <ListPagesBlock pagesCount={pagesCount} selectPage={selectPage} />
      )}
    </>
  );
};
