import React from 'react';
import { DashboardLayout } from '../components/layouts/DashboardLayout';
import StatisticByMethodBlock from '../components/statistic/byMethod/StatisticByMethodBlock';

const StatisticSchoolsByMethod = () => {
    return (
        <DashboardLayout>
            <StatisticByMethodBlock/>
        </DashboardLayout>
    );
};

export default StatisticSchoolsByMethod;