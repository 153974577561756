import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'components/Alert';
import styles from './DashboardLayout.module.scss';
import { useHistory } from 'react-router-dom';
import { finishLoading, startLoading } from '../../redux/actions/setAppActions';
import axios from 'axios';
import { ADMIN_AUTH } from '../../redux/types';
import { setDashboardMunicipality, setDashboardRegion } from '../../redux/actions/setDashboardActions';

export const DashboardLayout = ({ children }) => {
    const { alert } = useSelector((state) => state.app);
    const { admin } = useSelector((state) => state.admin);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (!admin) {
            (async () => {
                try {
                    dispatch(startLoading());
                    const { data } = await axios.get('admin/auth');

                    dispatch({ type: ADMIN_AUTH, payload: data });
                } catch (e) {
                    history.push('/login');
                } finally {
                    dispatch(finishLoading());
                }
            })();
        }
    }, [admin]);

    useEffect(() => {
        if(admin?.type === "onlyMunicipality"){
            console.log(admin)
            if(admin?.region?.name){
                dispatch(setDashboardRegion(admin?.region?.name))
            }
            if(admin?.municipality){
                dispatch(setDashboardMunicipality(admin?.municipality))
            }
        }
    }, [admin]);

    return (
        <div className={styles.dashboardLayout}>
            <div className={styles.container}>
                {!!alert && <Alert text={alert.text} type={alert.type} />}

                {children}
            </div>
        </div>
    );
};
