import { COMMENTS_LIST, COMMENTS_UPDATE } from './types';

const initialState = {
  list: null
};

export const commentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMENTS_LIST:
      return { ...state, list: action.payload };
    case COMMENTS_UPDATE: {
      const { id, updatedCommit } = action.payload;
      const index = state.list.findIndex(({ _id }) => _id === id);
      const newList = [...state.list];

      newList.splice(index, 1, updatedCommit);

      return { ...state, list: newList };
    }
    default:
      return state;
  }
};
