import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import LoginForm from 'components/LoginForm';
import {
    finishLoading,
    showAlert,
    startLoading
} from 'redux/actions/setAppActions';
import { ADMIN_AUTH } from 'redux/types';

export const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const loginHandler = async (adminData) => {
        try {
            dispatch(startLoading());
            const { data } = await axios.post('admin/login', adminData);

            dispatch({ type: ADMIN_AUTH, payload: data });
            history.push('/');
        } catch (e) {
            const text = e.response?.data?.message || e.message;
            dispatch(showAlert({ type: 'error', text }));
        } finally {
            dispatch(finishLoading());
        }
    };

    return <LoginForm loginHandler={loginHandler} />;
};
