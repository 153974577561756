export const STATISTIC_PARAMS = {
    pupils: {
        heading: 'Статистика учеников',
    },
    psychs: {
        heading: 'Статистика психологов'
    },
    activePsychs: {
        heading: 'Статистика активных психологов'
    },
    studentsPassedTest: {
        heading: 'Статистика учеников, прошедших тестирование'
    },
    alarms: {
        heading: 'Статистика запросов на разговор'
    },
    meetings: {
        heading: 'Статистика Назначенных встреч'
    },
    advices: {
        heading: 'Статистика отправленных рекомендаций'
    }
}