import React from 'react';

import close from '../static/img/ic-close.svg';
import styles from './Modal.module.scss';

export const Modal = ({ title = '', onClose, children }) => {
  return (
    <div className={styles.modal}>
      <div className={styles.inner}>
        <h3>{title}</h3>

        {children}

        <button onClick={onClose} className={styles.close}>
          <img alt="Close Icon" src={close} />
        </button>
      </div>
    </div>
  );
};
