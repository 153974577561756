import React, { useState } from 'react';
import styles from './DashboardMethodsData.module.scss';
import { useQuery } from '@tanstack/react-query';
import { fetchDashboardMethods } from '../../../api/fetchGeneralStatistic';
import { Loader } from '../../Loader';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const DashboardMethodsData = () => {
    const [currentTimeframe, setCurrentTimeframe] = useState("total");
    const dashboard = useSelector(state => state.dashboard)

    const changeTimeframe = (timeframe) => {
        setCurrentTimeframe(timeframe);
    }

    const { data, error, isLoading } = useQuery({
        queryKey: ['statistic/dashboard/methods', dashboard],
        queryFn: async () => {
            return await fetchDashboardMethods(dashboard.currentRegion, dashboard.currentMunicipality?._id, dashboard.currentSchool?._id)
        }
    });


    return (
        <div className={styles.methodsData}>
            {isLoading && <Loader />}

            {error && <div className="data-error">{error.message}</div>}

            {data && <>
                <div className={styles.methodsDataHeader}>
                    <p className={styles.methodsDataHeaderText}>Наиболее популярные методики</p>
                    <div className={styles.periodFilterBlock}>
                        <button onClick={() => changeTimeframe('today')}
                                className={`${styles.periodFilter} ${currentTimeframe === 'today' && styles.periodFilterActive}`}>За
                            сегодня
                        </button>
                        <button onClick={() => changeTimeframe('month')}
                                className={`${styles.periodFilter} ${currentTimeframe === 'month' && styles.periodFilterActive}`}>За
                            месяц
                        </button>
                        <button onClick={() => changeTimeframe('year')}
                                className={`${styles.periodFilter} ${currentTimeframe === 'year' && styles.periodFilterActive}`}>За
                            год
                        </button>
                        <button onClick={() => changeTimeframe('total')}
                                className={`${styles.periodFilter} ${currentTimeframe === 'total' && styles.periodFilterActive}`}>За
                            всё время
                        </button>
                    </div>
                </div>
                <div className={styles.methodsDataTable}>
                    <div className={styles.methodsDataTableHeading}>
                        <p className={styles.methodsDataTableHeadingText}>Направление</p>
                        <p className={styles.methodsDataTableHeadingText}>Количество</p>
                    </div>
                    <div className={styles.methodsDataTableContent}>
                        {
                            data[currentTimeframe].map((item, index) => {
                                return <Link to={'/statistic/byMethod/' + item.conditionId + '/' + currentTimeframe} key={index} className={styles.methodsDataTableLine}>
                                    <div className={styles.methodsDataTableLineNameBlock}>
                                        <p className={styles.methodsDataTableLineName}>{item.conditionName}</p>
                                    </div>
                                    <p className={styles.methodsDataTableLineCounter}>{item.count}</p>
                                </Link>;
                            })
                        }
                    </div>
                </div>
            </>}
        </div>
    );
};

export default DashboardMethodsData;