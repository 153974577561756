import styles from './GeneralStatisticContent.module.scss';

/**
 * @param {Object} data
 * @param {Object} data.psychs
 * @param {Number} data.psychs.psychsToday
 * @param {Number} data.psychs.psychsLoginThisMonth
 * @param {Number} data.psychs.psychsLoginToday
 * @param {Number} data.psychs.newPsychs
 * @param {Number} data.psychs.newPsychsToday
 * @param {Number} data.psychs.activePsychs
 * @param {Number} data.psychs.activePsychsToday
 * @param {Number} data.psychs.bannedPsychs
 * @param {Number} data.psychs.bannedPsychsToday
 * @param {Number} data.psychs.pilotPsychs
 * @param {Number} data.psychs.pilotPsychsToday
 *
 * @param {Object} data.pupils
 * @param {Number} data.pupils.pupils
 * @param {Number} data.pupils.pupilsToday
 * @param {Number} data.pupils.pupilsLoginToday
 *
 * @param {Object} data.tests
 * @param {Number} data.tests.total
 * @param {Number} data.tests.passed
 * @param {Number} data.tests.today
 *
 * @param {Object} data.alarms
 * @param {Number} data.alarms.total
 * @param {Number} data.alarms.today
 *
 * @param {Object} data.advices
 * @param {Number} data.advices.total
 * @param {Number} data.advices.today
 *
 * @param {Object} data.notes
 * @param {Number} data.notes.total
 * @param {Number} data.notes.today
 *
 * @param {Object} data.meetings
 * @param {Number} data.meetings.total
 * @param {Number} data.meetings.today
 *
 * @param {Object} data.tg
 * @param {Number} data.tg.psychsWithTgChat
 * @param {Number} data.tg.psychsTgChatToday
 * @param {Number} data.tg.pupilsWithTgChat
 * @param {Number} data.tg.pupilsTgChatToday
 *
 * @returns {JSX.Element}
 */
export const GeneralStatisticContent = ({ data }) => {
    const {
        psychs: {
            psychsTotal,
            psychsNew,
            psychsActive,
            psychsBanned,
            psychsPilot,
            psychsNewAndPending
        },
        psychsLastLogins: {
            today: psychsLoginToday,
            month: psychsLoginThisMonth,
        },
        pupilsLastLogins: {
            today: pupilsLoginToday,
        },
        pupils,
        tests,
        passedTests,
        alarms,
        advices,
        notes,
        meetings,
        tg: {
            psychsWithTgChat,
            psychsTgChatToday,
            pupilsWithTgChat,
            pupilsTgChatToday,
        },
    } = data;

    return (
        <div className={styles.wrapper}>
            <div className={styles.block}>
                <h4 className={styles.blockTitle}>Пользователи</h4>
                <div className={styles.inner}>
                    <p>
                        Учеников:{' '}
                        <span>
                            {pupils.total} ({pupils.today})
                        </span>
                    </p>

                    <p>
                        Психологов: <br />- всего:{' '}
                        <span>
                            {psychsTotal.total} ({psychsTotal.today})
                        </span>{' '}
                        <br />- подтвержденных:{' '}
                        <span>
                            {psychsActive.total} ({psychsActive.today})
                        </span>{' '}
                        <br />- неподтвержденных:{' '}
                        <span>
                            {psychsNewAndPending.total} ({psychsNew.today})
                        </span>{' '}
                        <br />- пилотных:{' '}
                        <span>
                            {psychsPilot.total} ({psychsPilot.today})
                        </span>{' '}
                        <br />- заблокированных:{' '}
                        <span>
                            {psychsBanned.total} ({psychsBanned.today})
                        </span>
                    </p>
                    <p>
                        Количество пользователей зашло в ЛК сегодня:{' '}
                        <span>{psychsLoginToday.total + pupilsLoginToday.total}</span>
                    </p>
                    <p>
                        Количество уникальных пользователей психологов, которые
                        заходили в этом месяце:{' '}
                        <span>{psychsLoginThisMonth.total}</span>
                    </p>
                </div>
            </div>

            <div className={styles.block}>
                <h4 className={styles.blockTitle}>Действия пользователей</h4>
                <div className={styles.inner}>
                    <p>
                        Общее количество запросов на разговор со специалистом:{' '}
                        <span>
                            {alarms.total} ({alarms.today})
                        </span>
                    </p>
                    <p>
                        Общее количество рекомендаций:{' '}
                        <span>
                            {advices.total} ({advices.today})
                        </span>
                    </p>
                    <p>
                        Общее количество заметок:{' '}
                        <span>
                            {notes.total} ({notes.today})
                        </span>
                    </p>
                    <p>
                        Общее количество встреч:{' '}
                        <span>
                            {meetings.total} ({meetings.today})
                        </span>
                    </p>
                </div>
            </div>

            <div className={styles.block}>
                <h4 className={styles.blockTitle}>Методики</h4>
                <div className={styles.inner}>
                    <p>
                        Общее количество назначенных методик:{' '}
                        <span>{tests.total}</span>
                    </p>
                    <p>
                        Общее количество пройденных методик:{' '}
                        <span>{passedTests.total}</span>
                    </p>
                    <p>
                        Количество методик, назначенных сегодня:{' '}
                        <span>{tests.today}</span>
                    </p>
                </div>
            </div>

            <div className={styles.block}>
                <h4 className={styles.blockTitle}>Телеграм</h4>
                <div className={styles.inner}>
                    <p>
                        Психологов, подключённых к чат-боту:{' '}
                        <span>
                            {psychsWithTgChat.total} ({psychsTgChatToday.total})
                        </span>
                    </p>
                    <p>
                        Учеников, подключённых к чат-боту:{' '}
                        <span>
                            {pupilsWithTgChat.total} ({pupilsTgChatToday.total})
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
};
