import React from 'react';

import { getDateTime } from 'helpers/dates';
import { ListsItemDetail } from 'components/common/ListsItemDetail';
import styles from 'static/scss/ListsItem.module.scss';

/**
 * @param {Object} item
 * @param {String} item._id
 * @param {String} item.title
 * @param {String} item.text
 * @param {String} item.type
 * @param {String} item.note
 * @param {Date} item.createdAt
 * @param {Date} item.updatedAt
 *
 * @param {Object} item.psych
 * @param {String} item.psych._id
 * @param {String} item.psych.name
 * @param {String} item.psych.surname
 * @param {String} item.psych.patronymic
 * @param {String} item.psych.email
 * @param {String} item.psych.login
 * @param {String} item.psych.status
 * @param {String} item.psych.city
 * @param {String} item.psych.phone
 * @param {String} item.psych.institution
 * @param {String} item.psych.birthday
 * @param {String} item.psych.note
 *
 * @param {String} activeItem
 * @param {Function} activeItemHandler
 * @param {Function} saveNote
 */

export const CommentsItem = ({
    item,
    activeItem,
    activeItemHandler,
    saveNote
}) => {
    const { _id, title, text, note, psych, createdAt } = item;
    const isActive = activeItem === _id;

    const onClickHandler = () => {
        activeItemHandler(_id);
    };

    return (
        <li className={styles.item}>
            <div className={styles.header}>
                <div className={styles.name}>
                    <div>{title}</div>
                    <div>
                        <span>Отправлено:</span>{' '}
                        <span className={styles.letterSpacing}>
                            {getDateTime(createdAt)}
                        </span>
                    </div>
                </div>
                <div>
                    <button className="button" onClick={onClickHandler}>
                        Подробнее
                    </button>
                </div>
            </div>

            {isActive && (
                <ListsItemDetail
                    id={_id}
                    email={psych.email}
                    phone={psych.phone}
                    login={psych.login}
                    name={psych.name}
                    surname={psych.surname}
                    patronymic={psych.patronymic}
                    city={psych.city}
                    institution={psych.institution}
                    birthday={psych.birthday}
                    note={note}
                    suggestionText={text}
                    saveNote={saveNote}
                    isShowLastLogin
                />
            )}
        </li>
    );
};
