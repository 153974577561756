import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';

import { getDate, getDateTime } from 'helpers/dates';
import { changePsychologistStatus } from 'redux/actions/setPsychologistsActions';
import styles from 'static/scss/ListsItem.module.scss';

/**

 * @param {String} id
 * @param {String} email
 * @param {String} login
 * @param {String} name
 * @param {String} surname
 * @param {String} patronymic
 * @param {String} city
 * @param {String} phone
 * @param {String} institutionType
 * @param {String} institution
 * @param {String} birthday
 * @param {String} lastLogin
 * @param {String} [status]
 * @param {String} message
 * @param {String} note
 * @param {String} [dnevnikruPersonId]
 * @param {String} [suggestionText]
 * @param {Boolean} isShowLastLogin
 * @param {Function} saveNote
 * @param {String} source
 */

export const ListsItemDetail = ({
    id,
    email,
    login,
    name,
    surname,
    patronymic,
    city,
    phone,
    institutionType,
    institution,
    birthday,
    lastLogin,
    message,
    note,
    dnevnikruPersonId,
    status,
    saveNote,
    suggestionText,
    isShowLastLogin = false,
    source = 'testu'
}) => {
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.app);
    const [noteState, setNoteState] = useState('');

    useEffect(() => {
        setNoteState(note);
    }, [note]);

    const onClickHandler = (e) => {
        const newStatus = e.target.getAttribute('data-status');

        dispatch(changePsychologistStatus(id, newStatus, source));
    };

    const changeNoteHandler = (e) => {
        setNoteState(e.target.value);
    };

    const saveNoteHandler = () => {
        saveNote(id, noteState);
    };

    return (
        <div className={styles.more}>
            <div className={styles.flex}>
                <div className={styles.userInfo}>
                    {name && surname && (
                        <div>
                            <span>ФИО:</span> {surname} {name} {patronymic}
                        </div>
                    )}

                    <div>
                        <span>Эл. почта:</span> {email}
                    </div>
                    {phone && (
                        <div>
                            <span>Номер телефона:</span>{' '}
                            <span className={styles.letterSpacing}>
                                {phone}
                            </span>
                        </div>
                    )}
                    {login && (
                        <div>
                            <span>Логин:</span> {login}
                        </div>
                    )}
                    {birthday && (
                        <div>
                            <span>Дата рождения:</span> {getDate(birthday)}
                        </div>
                    )}
                    {city && (
                        <div>
                            <span>Город:</span> {city}
                        </div>
                    )}
                    {institutionType && (
                        <div>
                            <span>Тип учреждения:</span> {institutionType}
                        </div>
                    )}
                    {institution && (
                        <div>
                            <span>Название учреждения:</span> {institution}
                        </div>
                    )}
                    {isShowLastLogin && (
                        <div>
                            <span>Дата последнего входа в ЛК:</span>{' '}
                            {getDateTime(lastLogin)}
                        </div>
                    )}
                    {dnevnikruPersonId && (
                        <div>
                            <span>ID в Дневник.ру:</span> {dnevnikruPersonId}
                        </div>
                    )}
                    {message && (
                        <div>
                            <span>Сообщение:</span> {message}
                        </div>
                    )}

                    {suggestionText && (
                        <div>
                            <span>Сообщение:</span> <br />
                            <p className="textarea-autosize">
                                {suggestionText}
                            </p>
                        </div>
                    )}
                </div>

                {status && (
                    <div className={styles.buttons}>
                        {['New', 'Pilot'].includes(status) && (
                            <button
                                className="button"
                                data-status="Active"
                                onClick={onClickHandler}
                                disabled={loading}
                            >
                                Подтвердить
                            </button>
                        )}
                        {status === 'New' && (
                            <button
                                className="button"
                                data-status="Pilot"
                                onClick={onClickHandler}
                                disabled={loading}
                            >
                                Пилот
                            </button>
                        )}
                        {['Active', 'New', 'Pilot'].includes(status) && (
                            <button
                                className="button"
                                data-status="Banned"
                                onClick={onClickHandler}
                                disabled={loading}
                            >
                                Заблокировать
                            </button>
                        )}
                        {status === 'Banned' && (
                            <button
                                className="button"
                                data-status="Active"
                                onClick={onClickHandler}
                                disabled={loading}
                            >
                                Разблокировать
                            </button>
                        )}
                    </div>
                )}
            </div>

            <div className={styles.note}>
                <span>Заметка:</span>
                <div>
                    <TextareaAutosize
                        className="textarea-autosize"
                        value={noteState}
                        onChange={changeNoteHandler}
                        name="note"
                        placeholder="Введите текст заметки (опционально)"
                        minRows={3}
                    />
                </div>
                <div className={styles.noteButtons}>
                    <button className="button" onClick={saveNoteHandler}>
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    );
};
