import React from 'react';
import styles from './ByMethodStatisticData.module.scss';
import ByMethodScaleData from './ByMethodScaleData';
import ExcelIcon from '../../../static/img/ic-excel.svg';
import ArrowLeftIcon from '../../../static/img/ic-arrowLeft.svg';
import ByMethodTable from './ByMethodTable';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchDashboardMethodReport, fetchDashboardMethodStats } from '../../../api/fetchGeneralStatistic';
import { Loader } from '../../Loader';
import { useSelector } from 'react-redux';

const ByMethodStatisticData = () => {
    const dashboard = useSelector((state) => state.dashboard);
    const {date, methodId} = useParams();

    const { data, error, isLoading } = useQuery({
        queryKey: ['statistic/dashboard/methodStats', date, methodId],
        queryFn: async () => {
            return await fetchDashboardMethodStats(date, methodId)
        }
    });

    //TODO: Добавить экспорт
    const handleDataExport = () => {
        fetchDashboardMethodReport(methodId, date, [
                ...(dashboard?.currentRegion ? dashboard.currentRegion : []),
                ...(dashboard?.currentMunicipality?.name ? dashboard.currentMunicipality.name : []),
                ...(dashboard?.currentSchool?.name ? dashboard.currentSchool.name : []),
        ]).catch(e => console.log(e))
    }

    return (
        <div className={styles.byMethodStatisticData}>
            <Link to={'/statisticByMethods'} className={styles.backLink}>
                <img src={ArrowLeftIcon} alt="Назад"/>
                <p>Назад</p>
            </Link>

            <h2 className={styles.heading}>Статистика по методикам</h2>
            {(dashboard.currentRegion || dashboard.currentMunicipality.name || dashboard.currentSchool.name)
                && <div className={styles.filters}>
                    {dashboard.currentRegion && <p className={styles.filtersText}>{dashboard.currentRegion}</p>}
                    {dashboard.currentMunicipality.name && <p className={styles.filtersText}>{dashboard.currentMunicipality.name}</p>}
                    {dashboard.currentSchool.name && <p className={styles.filtersText}>{dashboard.currentSchool.name}</p>}
                </div>}
            {isLoading && <Loader />}

            {error && <div className="data-error">{error.message}</div>}

            {data && <>
                <div className={styles.headerRow}>
                    <div className={styles.descriptionBlock}>
                        <div className={styles.bigDescription}>
                            <p className={styles.bigDescriptionField}>Наименование методики:</p>
                            <p className={styles.bigDescriptionValue}>{data?.name}</p>
                        </div>
                        <div className={styles.bigDescription}>
                            <p className={styles.bigDescriptionField}>Дата назначения методики:</p>
                            <p className={styles.bigDescriptionValue}>{date}</p>
                        </div>
                        <div className={styles.description}>
                            <p className={styles.descriptionField}>Назначена:</p>
                            <p className={styles.descriptionValue}>{data.totalTests} респондентам</p>
                        </div>
                        <div className={styles.description}>
                            <p className={styles.descriptionField}>Пройдена:</p>
                            <p className={styles.descriptionValue}>{data.passedTests} респондентами</p>
                        </div>
                    </div>
                    <button onClick={handleDataExport} className={styles.exportButton}>
                        <p className={styles.exportButtonText}>Экспорт в excel</p>
                        <img alt="" src={ExcelIcon} />
                    </button>
                </div>
                <div className={styles.content}>
                    {
                        data?.scales?.map((scale, index) => {
                            return <ByMethodScaleData scale={scale} key={index} />
                        })
                    }
                    <ByMethodTable scalesData={data.scales} />
                </div>
            </>}

        </div>
    );
};

export default ByMethodStatisticData;