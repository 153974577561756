import styles from './ByMethodPagination.module.scss';
import ArrowLeftIcon from '../../../static/img/ic-arrowLeft.svg';
import ArrowRightIcon from '../../../static/img/ic-arrowRight.svg';

export const ByMethodPagination = ({currentPage, setCurrentPage, totalPages}) => {
    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const handlePageMinus = () => {
        if(currentPage === 0){
            return
        }

        setCurrentPage(currentPage - 1);
    }

    const handlePagePlus = () => {
        if(currentPage === (totalPages | 0)){
            return
        }

        setCurrentPage(currentPage + 1);
    }

    return (
       <div className={styles.byMethodPagination}>
            <div className={styles.row}>
                <div className={styles.pageButtons}>
                    <button onClick={handlePageMinus} className={styles.pageButton}>
                        <img src={ArrowLeftIcon} alt="" />
                    </button>
                    {Array(totalPages + 1 | 1).fill(0).map((page, i) => {
                        return <button onClick={() => handlePageChange(i)} className={`${styles.pageButton} ${currentPage === i && styles.pageButtonActive}`}>
                            {i + 1}
                        </button>
                    })}
                    <button onClick={handlePagePlus} className={styles.pageButton}>
                        <img src={ArrowRightIcon} alt="" />
                    </button>
                </div>
            </div>
       </div>
    )
};
